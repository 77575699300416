import ManageTravelBookButtonComponent from './components/manage-travel-book-button.component'

document.addEventListener('DOMContentLoaded', async () => {
    new ManageTravelBookButtonComponent()
    $(document).on({
        wpet_ajax_build: () => new ManageTravelBookButtonComponent(),
        wpet_ajax_navigation: () => new ManageTravelBookButtonComponent(),
    })

    if ($('[data-travel-book-content-wrapper]').length > 0) {
        const { default: ManageTravelBookActionsComponent } = await import(
            './components/manage-travel-book-actions.component' /* webpackChunkName: "scripts/manage-travel-book-actions.component" */
        )
        new ManageTravelBookActionsComponent()

        if ($('[data-iris-map]').length > 0) {
            const { default: ManageTravelBookMapComponent } = await import(
                './components/manage-travel-book-map.component' /* webpackChunkName: "scripts/manage-travel-book-map.component" */
            )
            new ManageTravelBookMapComponent()

            $(document).on({
                wpet_ajax_build: () => new ManageTravelBookMapComponent(),
                wpet_ajax_navigation: () => new ManageTravelBookMapComponent(),
            })

            import('./components/map.component' /* webpackChunkName: "map.component" */).then(
                ({ default: MapComponent }) => {
                    new MapComponent()
                },
            )
        }
    }
})
