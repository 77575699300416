export default class ManageTravelBookButtonComponent {
    constructor() {
        this.handleButton()
    }

    handleButton() {
        $(document).off('click', '[data-id-object]')
        $(document).on('click', '[data-id-object]', async (e) => {
            await this.onButtonClick(e.currentTarget)
        })

        $(window).on('keydown', async (e) => {
            // Click on button with enter
            if (e.which == 13) {
                const target = e.target
                if (target.hasAttribute('data-id-object')) {
                    await this.onButtonClick(e.target)
                }
            }
        })
    }

    async onButtonClick(value) {
        const contentWrapper = '[data-travel-book-content-wrapper]'
        const counter = '[data-travel-book-counter]'

        let url = `${IrisTravelBookSettings.iris_travel_book_api_base_url}/manage-bookmark?id=${$(value).attr('data-id-object')}`

        if ($(contentWrapper).length > 0) {
            const currentPage = $(contentWrapper).attr('data-current-page')
            url = `${IrisTravelBookSettings.iris_travel_book_api_base_url}/manage-bookmark?id=${$(value).attr('data-id-object')}&current_page=${currentPage}`
        }

        const query = await fetch(url)
        const dataReturned = await query.json()

        if (query.status === 200) {
            $(`[data-item-bookmark-wrapper=${$(value).attr('data-id-object')}]`).remove()

            $('[data-list-bookmark-wrapper]').each(function (index) {
                var countOffers = $(this).find('[data-item-bookmark-wrapper]').length
                if (countOffers === 0) {
                    $(this).remove()
                }
            })

            if ($('[data-item-bookmark-wrapper]').length === 0) {
                $('[data-travel-book-action-wrapper]').remove()
                $('[data-travel-book-content-wrapper]').html(dataReturned.html_content)
            }

            if ($(counter).length > 0) {
                $(counter).html(dataReturned.travel_book.length)
            }

            if ($(contentWrapper).length === 0) {
                $(`[data-id-object=${$(value).attr('data-id-object')}]`).each(
                    (indexAllObject, valueAllObject) => {
                        $(valueAllObject)
                            .find('i')
                            .removeClass(`${$(valueAllObject).attr('data-icon-empty')}`)
                        $(valueAllObject)
                            .find('i')
                            .removeClass(`${$(valueAllObject).attr('data-icon-filled')}`)
                        if (dataReturned.add_to_travel_book === true) {
                            $(valueAllObject).addClass('isInTravelBook')
                            $(valueAllObject)
                                .find('i')
                                .addClass(`${$(valueAllObject).attr('data-icon-filled')}`)
                            $(valueAllObject).attr('aria-pressed', 'true')
                        } else {
                            $(valueAllObject).removeClass('isInTravelBook')
                            $(valueAllObject)
                                .find('i')
                                .addClass(`${$(valueAllObject).attr('data-icon-empty')}`)
                            $(valueAllObject).attr('aria-pressed', 'false')
                        }
                    },
                )
            }

            $(document).trigger(
                'iris-travel-book/map/markers/remove',
                $(value).attr('data-id-object'),
            )
        }
    }
}
